import * as Types from "./types";
import { isMobile } from "react-device-detect";
import history from "@speed/common/src/components/history";

const initialState = {
  history: history,
  isMobile: isMobile,
  isToastOpen: false,
  toastMessage: "",
  toastVariant: "success",
  toastAutoHideDuration: 4000,
  notificationClass: "",
  isPageLoader: false,
  pageLoaderText: "",
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case Types.SHOW_TOAST_MESSAGE:
      const { isToastOpen, toastMessage, toastVariant, toastAutoHideDuration } =
        action.payload;
      return {
        ...state,
        isToastOpen: isToastOpen,
        toastMessage: toastMessage,
        toastVariant: toastVariant,
        toastAutoHideDuration: toastAutoHideDuration || 4000,
        notificationClass: action.payload.className,
      };
    case Types.HIDE_TOAST_MESSAGE:
      return {
        ...state,
        isToastOpen: false,
      };
    case Types.SET_PAGE_LOADER:
      const { isPageLoader, pageLoaderText } = action.payload;
      return {
        ...state,
        isPageLoader,
        pageLoaderText,
      };
    default:
      return { ...state };
  }
};

export default common;
