import React from 'react';
import { Box, Skeleton } from "@mui/material";
import Text from '@speed/common/src/components/Text/Text';
import { sats, balanceText } from '../messages';
import classNames from "classnames";

const Balance = ({ totalAmount, balanceClassName, balanceLoader }) => {
  return (
    <Box className={classNames(balanceClassName)}>
      {balanceLoader ? <Skeleton animation="wave" width={150} className="loader-balance" /> : <Text
        size={12}
        className="grey-text"
        font="semibold"
        variant="subtitle1"
      >
        {balanceText(totalAmount, sats)}
      </Text>}
    </Box>
  );
};

export default Balance;