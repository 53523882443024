import {
  callAPIInterfaceLnDev,
  expireSession,
  getTimestamp,
} from "../../container/components/constants";
import * as Types from "./types";
import { sessionService } from "redux-react-session";
import { store } from "../store";
import { setPageLoader, showToast } from "../common/actions";
import { userErrorMsg } from "../../container/components/messages";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app } from "@speed/common/src/util/firebase";
import { Wallet } from "@speed/common/src/components/messages";

export const setInitialState = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_INITIAL_DATA,
    payload: data,
  });
};

export const setIsLoggedIn = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_LOGGED_IN,
    payload: data,
  });
};

export const updateUser = (data) => async (dispatch) => {
  const accountLists = data.accounts_details.filter((accountData) => {
    const account = accountData?.account;
    return account?.account_type !== Wallet;
  });
  data = {
    ...data,
    accounts_details: accountLists,
  };

  dispatch({
    type: Types.SET_USER,
    payload: data,
  });
};

export const resetPageLoader = () => async (dispatch) => {
  dispatch(
    setPageLoader({
      isPageLoader: false,
      pageLoaderText: "",
    })
  );
};

export const getUser = () => async (dispatch) => {
  dispatch(
    setPageLoader({
      isPageLoader: true,
      pageLoaderText: "Retrieving user...",
    })
  );

  return callAPIInterfaceLnDev("GET", "/user", "")
    .then(async (response) => {
      if (response) {
        const session = await sessionService.loadSession();
        await sessionService
          .saveSession({
            ...session,
            speed_acc_id: response.accounts_details?.[0]?.account?.id,
            liveMode: false, //LN.dev is working in only test mode.
          })
          .then(() => {
            dispatch(
              setInitialState({
                ...session,
                current_acc: response.accounts_details[0],
                liveMode: false, //LN.dev is working in only test mode.
              })
            );
          });
        dispatch(updateUser(response));
      }
      await getAccountFirebaseToken();

      dispatch(resetPageLoader());
      return response;
    })
    .catch((_e) => {
      dispatch(resetPageLoader());
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: userErrorMsg,
          toastVariant: "error",
        })
      );
      throw _e;
    });
};

export const setUpdatedSessionData = async (response) => {
  let currentSession = null;
  await sessionService
    .loadSession()
    .then((res) => (currentSession = res))
    .catch((_e) => {});
  await sessionService.saveSession({
    ...currentSession,
    ...response,
    last_action_time: new Date().getTime(),
  });
};

export const logOut = () => async () => {
  const { session } = await sessionService.loadSession();
  return await callAPIInterfaceLnDev(
    "POST",
    "/logout",
    JSON.stringify({ session })
  ).then(() => {
    expireSession();
  });
};

export const generateToken = async (obj) => {
  try {
    const { session, refreshToken, firebaseToken, isExpired } = obj;
    let currentSession = {};
    if (!isExpired) {
      store.dispatch(setIsLoggedIn(false));
      store.dispatch(
        setPageLoader({
          isPageLoader: true,
          pageLoaderText: "Logging in...",
        })
      );
    } else {
      currentSession = await sessionService.loadSession();
    }

    const response = await callAPIInterfaceLnDev(
      "POST",
      "/generate-token",
      JSON.stringify({ refresh_token: refreshToken })
    );
    const updatedSession = {
      ...currentSession,
      access_token: response.access_token,
      id_token: response.id_token,
      timestamp: getTimestamp(),
      session,
    };

    if (firebaseToken) {
      updatedSession.firebase_token = firebaseToken;
    }

    await sessionService.saveSession(updatedSession);
    store.dispatch(setInitialState(updatedSession));

    if (!isExpired) {
      //const authObj = getAuth(app);
      // signInWithCustomToken(authObj, updatedSession.firebase_token);
      store.dispatch(setIsLoggedIn(true));
      store.dispatch(resetPageLoader());
      localStorage.setItem("site_auth", "1");
    }

    return response;
  } catch (err) {
    store.dispatch(resetPageLoader());
    throw err;
  }
};

export const getAccountFirebaseToken = async () => {
  return callAPIInterfaceLnDev("GET", "/account/firebase-token").then(
    async (response) => {
      const session = await sessionService.loadSession();
      const updatedSession = {
        ...session,
        account_firebase_token: response.firebase_token,
      };
      await sessionService.saveSession(updatedSession);
      const authObj = getAuth(app);
      signInWithCustomToken(authObj, response.firebase_token);
    }
  );
};
