import React from "react";
import { Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { Text } from "@speed/common/src/components/Text/Text";
import CloseIcon from "@mui/icons-material/Close";
import { signIn, signUp } from "@speed/common/src/components/messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { send, speedAccountloginMsg } from "./components/messages";

const SignInBox = ({
  setSignInBox,
  setReceiveRequest,
  setSendPaymentButtonVisible,
  type,
}) => {
  const queryParams = new URLSearchParams({
    redirect: window.location.href,
    require_firebase_token: true,
  });
  return (
    <Box className="without-login-box send-payment-request">
      <Box
        height={15}
        width={15}
        onClick={() => {
          type === send
            ? setSendPaymentButtonVisible(false)
            : setReceiveRequest(false);
          setSignInBox(false);
        }}
        component="span"
        className="pointer-cursor closeIcon"
      >
        <CloseIcon sx={{ height: 15, width: 15 }} htmlColor="#848b9e" />
      </Box>
      <Box
        className="send-request-data"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box>
          <Text font="semibold" size={16} variant="h4">
            {speedAccountloginMsg}
          </Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <CustomLink
              size="small"
              bold={true}
              sx={{ paddingRight: "30px" }}
              href={
                process.env.REACT_APP_URL + `/login?${queryParams.toString()}`
              }
            >
              <Button
                variant="outlined"
                label={signIn}
                className="signIn-box-btn"
              ></Button>
            </CustomLink>
            <CustomLink
              size="small"
              bold={true}
              href={
                process.env.REACT_APP_URL +
                `/register?${queryParams.toString()}`
              }
            >
              <Button label={signUp} className="signIn-box-btn" />
            </CustomLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInBox;
