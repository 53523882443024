import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, Menu, MenuItem } from "@mui/material";
import { CustomAvatar } from "./Avatar/Avatar";
import {
  loggingOutMsg,
  logoutText,
  profileText,
  Wallet,
} from "@speed/common/src/components/messages";
import BackdropLoader from "./BackdropLoader";
import { Link } from "react-router-dom";
import AnchorLink from "@mui/material/Link";
import { useSelector } from "react-redux";
import { underMaintenanceStatus } from "../messages";

const HeaderUserProfile = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { maintenanceDetails } = useSelector((state) => state.common);
  const user = props?.user;
  const { currentAccount } = useSelector((state) => state.auth);
  const accountType = currentAccount?.account?.account_type;
  const profileMenuItemProps = props.profileHref
    ? {
        component: AnchorLink,
        href: props.profileHref,
        target: "_blank",
      }
    : {
        component: Link,
        to: "/settings/profile",
      };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    props.logOut();
  };

  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleClick(event);
    }
  };

  const isWalletSideBarVisible =
    accountType === Wallet
      ? maintenanceDetails?.status !== underMaintenanceStatus
      : true;

  return (
    <React.Fragment>
      <BackdropLoader
        open={props.isLoading}
        alt="Logging out..."
        text={loggingOutMsg}
        customClass="loading-in-auth"
      />
      <Box
        tabIndex={0}
        onKeyDown={handleEnterKeyPress}
        className="header-user-profile"
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        <CustomAvatar
          src={user?.profile_images?.large}
          userName={user?.name || ""}
        />
      </Box>
      <Menu
        id="header-profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.profile && isWalletSideBarVisible && (
          <MenuItem {...profileMenuItemProps} onClick={handleClose}>
            <AccountCircleIcon />
            {profileText}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleLogout()}>
          <PowerSettingsNewIcon />
          {logoutText}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export default HeaderUserProfile;
