import CustomNotification from "@speed/common/src/components/Notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import { pure } from "recompose";
import { hideToast } from "../../../redux/common/actions";

const Notification = () => {
  const dispatch = useDispatch();
  const { isToastOpen, toastMessage, toastVariant, toastAutoHideDuration, notificationClass } = useSelector((state) => state.common);

  return (
    <CustomNotification
      open={isToastOpen}
      onClose={(_event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        dispatch(hideToast());
      }}
      severity={toastVariant}
      message={toastMessage}
      className={notificationClass || ""}
      autoHideDuration={toastAutoHideDuration}
    />
  );
};

export default pure(Notification);