import { satsToBtcAmount } from "./components/constants";

// Password policy
export const min8Char = "Password must be at least 8 characters";
export const letter = "Have at least 1 letter ( a,b,c,d…)";
export const oneNumber = "Have at least 1 number ( 1,2,3…)";
export const letterAndNumber = "Include both uppercase and lowercase letters";
export const passwordPolicy = [min8Char, letter, oneNumber, letterAndNumber];

export const noDataAvailable = "No data available";
export const viewAll = "View all";
export const copied = "Copied";
export const payVia = "Pay via";
export const timerTooltip = "Time remaining until bitcoin price update";
export const openWalletLabel = "Open Wallet";
export const copyAddressLabel = "Copy Address";
export const anyAmount = "Any Amount";
export const moreQuestionLabel = "More Questions ?";
export const reachOutText = [
  "You can reach out to us ",
  "here ",
  "for more information",
];
export const pageTitleLabel = "Page Title";
export const pageTitleDesc = "Page description here";
export const validAmount = "Please enter valid amount";
export const amountValidate = "Amount should be above 0";
export const congratulationText = "Congratulations!";
export const gotCashbackText = "You got a Cashback";
export const emailQRLabel = "Email QR";
export const cancelCashbackLabel = "I don’t want";
export const sendEmailCashbackLabel = "Send";
export const sendEmailCashbackPlaceholder = "Enter your email ";
export const successRedeemText = `You've successfully 
redeemed your cashback.`;
export const invoiceInfo = "Invoice Information";
export const view = "View";
export const hide = "Hide";
export const from = "From";
export const invoice = "Invoice";
export const memoTitle = "Memo";
export const footerTitle = "Footer";
export const to = "To";
export const items = "item";
export const addNewPrice = "+ Add New Price";
export const presetAmountGrater =
  "Preset amount must be greater or equal to minimum amount.";
export const presetAmountLesser =
  "Preset amount must be less than or equal to maximum amount.";
export const presetAmountGreaterInvalid = (amount, currencyCode) => (
  <>
    Invalid amount. It must be greater than or equal to {amount} {currencyCode}
  </>
);
export const presetAmountLesserInvalid = (amount, currencyCode) => (
  <>
    Invalid amount. It must be lesser than or equal to {amount} {currencyCode}
  </>
);
export const amountExceedMsg = (type) =>
  `A ${type} for an amount more than 5 BTC cannot be created.`;
export const presetAmount = "Preset amount";
export const getStartedText = "Get Started";
export const payableAmount = "Payable amount";
export const paymentMethod = "Payment Method";
export const passwordPolicyLabel = "Password Instructions";

//invoice
export const qtyLabel = "Qty";
export const subTotal = "Subtotal";
export const shipping = "Shipping (Air)";
export const totalExcludingTax = "Total excluding tax";
export const salesTax = "Sales tax(1%)";
export const totalDue = "Total due";
export const amountPaidLabel = "Amount paid";
export const amountRemaining = "Amount remaining ";
export const dueDate = "Due Date";
export const close = "Close";
export const invoiceContactUsTxt = [
  "Got any queries? Contact us at",
  "or call us at",
];
export const shippingAddressIsAsBillingAddress =
  "Shipping address is as billing";
export const viewCalculationLabel = "View Calculation";
export const continueBtn = "Continue";
export const maxLengthValidation = (type, length) =>
  `${type} must be at most ${length} characters.`;
export const noOptions = "No Options";

// custom fields
export const textPlaceHolder = "Type here";
export const dropdownPlaceHolder = "Select option";
export const textLabel = "Custom field label text";
export const numberLabel = "Custom field label number";
export const dropdownLabel = "Custom field label dropdown";
export const validValue = "Please enter valid value";
export const whiteSpacePattern = /\S/;
export const noDataAdded = "No Data Added";

//Table Filter Messages
export const ascending = "Ascending";
export const descending = "Descending";
export const filter = "Filter";
export const operatorLabel = "Operator";
export const valueLabel = (prefixString) => `${prefixString} Value`;
export const operatorPlaceHolder = "Select Operator";
export const valuePlaceHolder = "Enter value";
export const submitButtonLabel = "Submit";
export const clearFilterButtonLabel = "Clear Filter";

export const connectSubMsg =
  "If you’re completing this form on behalf of a business, it must be completed by the business owner or someone with significant management responsibility of that business.";
export const staticText = "partners with Speed for secure payments.";
export const selectedAccountStaticText =
  "Your platform account will be permitted to create multiple payments for your account as soon as you establish a connection with it. Sensitive information for your account, such as webhook endpoints and payout, cannot be viewed by the platform account.";
export const twoFAMsg = "Two Factor Authentication";
export const selectAnAccount = "Select an account";
export const standardCapabilities = "Standard Connect capabilities";
export const twoFASubMsgPhone = (countryCode, phone, email) => (
  <>
    Please enter 6 digit verification code we have sent on your phone number{" "}
    {countryCode} {phone} {email ? <>& email {email}</> : ""} below.
  </>
);
export const twoFASubMsgEmail =
  "Please enter the 6 digit authentication code from authenticator app. This code will be valid for 15 minutes";
export const otpWillExpireMsg = "This OTP will expire in";
export const otpHasExpiredMsg = "OTP has been expired.";
export const resendMsg = "Resend";
export const mfaResendCodeMsg = (fromAuth = false) =>
  `New code sent to your registered mobile number${
    fromAuth ? " and email" : ""
  }.`;
export const addNewAccount = "Add New Account";
export const searchAccount = "Search account";
export const alreadyConnectAccount =
  "This account is already connected with this platform account";
export const onlyWalletInConnect = `You only have a wallet account, thus in order to connect your account with platform account, you must first create a Speed "Merchant Account" by clicking on "Add Account" and then select and connect it with a platform account.`;
export const alphaNumName = "Please enter a correct alphanumeric name";
export const accountTitle = (isEdit) =>
  `${isEdit ? "Edit" : "Add New"} Account`;
export const businessNameLabel = "Business Name";
export const changeAccountMsg = (accountSetting) => (
  <>
    You can also change or edit your account name from your {accountSetting}{" "}
    anytime
  </>
);
export const companyPlaceholder = "Company";
export const countryLabel = "Country";
export const countryPlaceholder = "Select Country";
export const createAccount = "Create Account";
export const save = "Save";
export const accountSettingSmall = " Account settings";
export const goBack = "Go Back";
export const connect = "Connect";
export const platFormCan = "PLATFORM CAN";
export const platFormCannot = "PLATFORM CAN’T";
export const accountConnected =
  "The account connection procedure has been completed.";
export const loginBtn = "Login";
export const authenticate = "Authenticate";
export const validStartAmountMsg = "Enter start amount";
export const validEndAmountMsg = "Enter end amount";
export const validEndAmountGreaterStartAmountMsg =
  "Enter end amount greater than start amount";
export const transactionDate = "Transaction Date";
export const startDate = "Start Date";
export const endDate = "End Date";
export const enterValidDateMsg = (isEndDate = false) =>
  `Please select a valid ${isEndDate ? "end" : "start"} date`;
export const enterValidFutureDateMsg = (isEndDate = false) =>
  `Please select a ${
    isEndDate ? "end" : "start"
  } date less than or equal to today`;
export const enterValidMinRequiredDateMsg = (
  givenYear = 2021,
  isEndDate = false
) =>
  `Please select a ${
    isEndDate ? "end" : "start"
  } date in the year of ${givenYear} or after.`;
export const greaterEndDateMsg =
  "Please select a end date greater than start date.";
export const advanceFilter = "Advanced Filter";
export const applyFilter = "Apply Filter";
export const clearButtonLabel = "Clear";
export const speedFee = "Speed fee";
export const speedFees = "Speed fees";
export const authenticatingURL = "Authenticating URL";
export const reportIssue = "Report an issue";
export const reportLabel = "Report";
export const submitFeedback = "Submit your feedback";
export const issueWithTrans = "I have an issue in making payment";
export const submitIssueMsg = "Issue submitted";
export const issueWalletLabel = "Select wallet which you have used";
export const reasonLabel =
  "Provide more information like error message, failure etc";
export const selectWalletLabel = "Select wallet";
export const writeFeedback = "Your valuable feedback";
export const writeHere = "Write here";
export const thankyouMsg = "Thanks for your response";
export const reasonPlaceholder = "Type a reason...";
export const paymentMethodLabel = "Select payment method which you have used";
export const otherWallet = "Other Wallet";
export const enterWalletName = "Enter wallet name";
export const poweredBy = "Powered by";
export const edit = "Edit";

// Image Messages
export const imageInvalid = "Only .jpeg, .png, .jpg, .jfif images are allowed";
export const imageUploadError =
  "Something went wrong while uploading the image, try after some time.";
export const imageSizeInvalid = "File size should be less than 2 MB";
export const imageDimensionInvalid = (imgDimension) =>
  `Please choose image with a minimum height and width of ${imgDimension}px`;
export const underMaintenance = "We're under scheduled maintenance";
export const underMaintenanceDesc =
  "Service is currently under maintenance and will be back soon. Thank you for your patience.";
export const underMaintenanceInfo =
  "* During this period, transactions and app features will be temporarily unavailable. Your pending transactions will be available once we're back. Thanks for your patience!";
export const underMaintenanceStatus = "UNDER_MAINTENANCE";
export const scheduledStatus = "SCHEDULED";
export const operationalStatus = "OPERATIONAL";
export const depositCurrency = "Deposit currency";
export const selectNetwork = "Select network";
export const selectPaymentMode = "Select payment mode";
export const lightningCaps = "Lightning";
export const lightning = "lightning";
export const choosePaymentMode =
  "Please choose your preferred payment mode to complete the transaction";
export const bitcoin = "Bitcoin";
export const failedQrMsg =
  "Failed to generate QR code, please try other network.";
export const sats = "SATS";
export const onchain = "onchain";
export const promoCode = "Promo code";
export const ethereumLabel = "ethereum";
export const tronLabel = "tron";
export const cantLessThanMsg = ({
  currencyType,
  type,
  isBoltz,
  currency = "USDT",
}) => {
  let value;
  switch (type) {
    case lightning:
      value = currencyType ? "1 SAT" : "0.00000001 BTC";
      break;
    case onchain:
      if (isBoltz) {
        const minAmount = Number(process.env.REACT_APP_BOLTZ_MIN_LIMIT);
        const maxAmount = Number(process.env.REACT_APP_BOLTZ_MAX_LIMIT);
        return currencyType
          ? `Minimum ${minAmount} SATS and Maximum ${maxAmount} SATS`
          : `Minimum ${satsToBtcAmount(
              minAmount
            )} BTC and Maximum ${satsToBtcAmount(maxAmount)} BTC`;
      } else {
        value = currencyType ? "1000 SATS" : "0.00001 BTC";
      }
      break;
    case ethereumLabel:
    case tronLabel:
      return `Minimum 10 ${currency}`;

    default:
      value = "";
  }

  return `Minimum ${value}`;
};
