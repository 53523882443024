import CustomSelect from "@speed/common/src/components/Select/Select";
import { Box, Skeleton, InputBase, MenuItem } from "@mui/material";
import { Text } from "@speed/common/src/components/Text/Text";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { accountIcons } from "@speed/common/src/components/images";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { noResultfound } from "@speed/common/src/components/messages";
import { sessionService } from "redux-react-session";
import classNames from "classnames";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { searchAccount } from "../messages";
import { callAPIInterfaceLnDev } from "../../components/constants";
import { setPageLoader } from "../../../redux/common/actions";
import { store } from "../../../redux/store";

const AccountSelection = ({ changeAccountFlag }) => {
  const { user, currentAccount } = useSelector((state) => state.auth);

  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [allAccountsInfo, setAllAccountsInfo] = useState();

  const loadInitialData = async () => {
    const accountRoleLists = user.accounts_details.filter((accountData) => {
      const role = accountData?.role;
      return role?.role_name !== "Developer";
    });

    let accountLists = accountRoleLists.map(
      (accountData) => accountData?.account
    );

    let mode = user.accounts_details.map(
      (accountData) => accountData.is_last_viewed_livemode
    );

    setAllAccountsInfo(accountLists);
    setAccountDetails(accountLists, accountLists[0].id, false, false, mode[0]);
  };

  useEffect(() => {
    changeAccountFlag && setOpenDropdownMenu(true);
  }, [changeAccountFlag]);

  useEffect(() => {
    loadInitialData();
  }, [user, currentAccount]);

  const setAccountDetails = async (
    accountList,
    id,
    reloadPage,
    changedAccount,
    mode
  ) => {
    let session = await sessionService.loadSession();
    session &&
      sessionService
        .saveSession({ ...session, speed_acc_id: id, liveMode: mode })
        .then(async () => {
          let currentSelectedAccount = accountList.filter(
            (account) => account.id === id
          )[0];
          setSelectedAccount(currentSelectedAccount);
          if (reloadPage) {
            store.dispatch(
              setPageLoader({
                isPageLoader: true,
                pageLoaderText: "Switching Account...",
              })
            );
            await callAPIInterfaceLnDev("GET", "/select-account");
            window.location.reload();
          } else {
            let currentSelectedAccount = accountList.filter(
              (account) => account.id === id
            )[0];
            setSelectedAccount(currentSelectedAccount);
          }
        });
  };

  const handleAccountChange = async (menuAccountId) => {
    const result = user.accounts_details.find(
      (acc) => acc?.account?.id === menuAccountId
    );
    await setAccountDetails(
      allAccountsInfo,
      menuAccountId,
      true,
      true,
      result.is_last_viewed_livemode
    );
  };

  const accountIconElement = (type) => {
    return (
      <Box className="switch-account-icon">
        {accountIcons()[type ? type : "Merchant"]}
      </Box>
    );
  };

  return (
    <CustomSelect
      onKeyDownCapture={(event) => {
        if (event.key === "Tab") {
          event.stopPropagation();
        }
        if (event.key === "Escape") {
          setOpenDropdownMenu(false);
        }
      }}
      open={openDropdownMenu}
      onClose={() => {
        setOpenDropdownMenu(false);
      }}
      onOpen={() => {
        setOpenDropdownMenu(true);
      }}
      className="header-account-select"
      variant="standard"
      labelId="switch-account"
      id="header-account-select"
      onChange={() => {
        setOpenDropdownMenu(false);
      }}
      MenuProps={{
        id: "header-account-select-menu",
      }}
      renderValue={() => {
        if (selectedAccount) {
          return (
            <MenuItem value={selectedAccount.id}>
              {accountIconElement(selectedAccount.account_type)}
              <Text component="span" size={18} noWrap>
                {selectedAccount.name}
              </Text>
            </MenuItem>
          );
        } else {
          return (
            <MenuItem value="loader">
              <Skeleton animation="wave" width={150} />
            </MenuItem>
          );
        }
      }}
    >
      <CustomAutoComplete
        open
        id="header-account-select-account"
        autoHighlight
        disablePortal
        noOptionsText={
          <Text size={14} align="center" className="grey-text">
            {noResultfound}
          </Text>
        }
        showLabel={false}
        getOptionLabel={(option) => option.name}
        name="speed-accounts"
        options={allAccountsInfo?.map((account) => {
          return {
            id: account.id,
            account_type: account.account_type,
            name: account.name,
          };
        })}
        onChange={(_event, value) => {
          value?.id && setOpenDropdownMenu(false);
          if (
            value?.id &&
            (selectedAccount?.id !== value.id || allAccountsInfo?.length === 1)
          ) {
            handleAccountChange(value.id);
          }
        }}
        renderInput={(params) => {
          return (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                value: null,
              }}
              autoFocus
              placeholder={searchAccount}
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <MenuItem
              {...props}
              className={classNames(
                props["data-option-index"] === 0 &&
                  selectedAccount?.id === option.id &&
                  "Mui-selected"
              )}
              key={option.id}
              value={option.id}
            >
              {accountIconElement(option.account_type)}
              <TruncatedTextTooltip
                textValue={option.name}
                cellWidth="134px"
                className="custom-tooltip"
              />
            </MenuItem>
          );
        }}
      />
    </CustomSelect>
  );
};

export default AccountSelection;
