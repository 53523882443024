import { Switch, Route } from "react-router-dom";
import lndev from "./components";

const Main = () => {
  return (
    <Switch>
      <Route exact path="/" component={lndev} />
    </Switch>
  );
};

export default Main;