import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { lightningLogo } from "@speed/common/src/components/images";
import { Input } from "@speed/common/src/components/Input/Input";
import { decode } from "light-bolt11-decoder";
import {
  lnInvoicePlaceholder,
  sendPayment,
  paymentSent,
  totalAmount,
  transactionFees,
  lightningPayment,
  showLess,
  showMore,
  invalidWithdraw,
  alreadyPaidInvoice,
  sats,
  amountPlaceholder,
  unableSendPayment,
  send,
  paymentProcessedMsg,
  alreadyPaid,
  paid,
  failed,
  unpaid,
  success,
  error,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import ToastMessage from "../Common/ToastMessage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "@speed/common/src/components/Text/Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import * as yup from "yup";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import {
  callAPIInterfaceLnDev,
  minSat,
  minSendSat,
  failureReasonNoRoute,
} from "../constants";
import { useSelector } from "react-redux";
import { amount } from "@speed/common/src/components/messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import SignInBox from "../../SignInBox";
import { maxSat } from "@speed/common/src/components/constants";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { sessionService } from "redux-react-session";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { app } from "@speed/common/src/util/firebase";

const Send = ({ setBalances }) => {
  let timeoutId;

  const [focusWalletAddress, setFocusWalletAddress] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [sendButtonVisible, setSendPaymentButtonVisible] = useState(false);
  const [showMorePayment, setShowMorePayment] = useState(false);
  const [decodeData, setDecodeData] = useState({});
  const [isPaymentDecodeLoading, setPaymentDecodeLoading] = useState(false);
  const [isPaymentSendLoading, setIsPaymentSendLoading] = useState(false);
  const [signInBox, setSignInBox] = useState(false);
  const [decodeAmount, setDecodeAmount] = useState(true);
  const [paymentRecieve, setPaymentReceive] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");
  const [sendResponse, setSendResponse] = useState({});
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const paymentStatusRef = useRef(paymentStatus);

  const amountValidation = !decodeAmount
    ? {
        amount: yup.number().required().integer().min(minSendSat).max(maxSat),
      }
    : {
        amount: yup.number().required().integer().min(minSat).max(maxSat),
      };

  const validationSchema = yup.object({
    ln_invoice: yup.string().required(""),
    ...amountValidation,
  });

  useEffect(() => {
    if (sessionStorage.getItem("lnInvoice")) {
      setFieldValue("ln_invoice", sessionStorage.getItem("lnInvoice"));
      sessionStorage.removeItem("lnInvoice");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    paymentStatusRef.current = paymentStatus;
    let withdrawResponse = {};
    if (paymentStatusRef.current === unpaid) {
      timeoutId = setTimeout(() => {
        if (
          paymentStatusRef.current !== paid &&
          paymentStatusRef.current !== failed
        ) {
          withdrawResponse = {
            type: unpaid,
            data: paymentRecieve,
            description: decodeData?.description,
          };
          setSendResponse(withdrawResponse);
          setIsPaymentSendLoading(false);
          setSendRequest(false);
        }
      }, 30000);
    } else if (paymentStatusRef.current === paid) {
      withdrawResponse = {
        type: success,
        data: paymentRecieve,
        description: decodeData?.description,
      };
      setSendResponse(withdrawResponse);
      setBalances(true);
      setIsPaymentSendLoading(false);
      setSendRequest(false);
      clearTimeout(timeoutId);
    } else if (paymentStatusRef.current === failed) {
      if (paymentRecieve?.failure_reason?.includes(alreadyPaid)) {
        withdrawResponse = {
          type: error,
          data: {
            errorMsg: alreadyPaidInvoice,
          },
        };
        setSendResponse(withdrawResponse);
        setIsPaymentSendLoading(false);
        setSendRequest(false);
      } else if (
        paymentRecieve.failure_reason?.includes(failureReasonNoRoute)
      ) {
        withdrawResponse = {
          type: error,
          data: {
            errorMsg: unableSendPayment,
          },
        };
        setSendResponse(withdrawResponse);
        setIsPaymentSendLoading(false);
        setSendRequest(false);
      }
      clearTimeout(timeoutId);
    }
  }, [paymentStatus]);

  const formik = useFormik({
    initialValues: {
      ln_invoice: "",
      amount: 0,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, setFieldValue, isValid, dirty } = formik;

  const getFirebasePayment = async (accountId) => {
    const session = await sessionService.loadSession();
    const db = getFirestore(app);
    const userRef = doc(
      db,
      "account",
      session.speed_acc_id,
      "withdraws",
      accountId
    );
    try {
      onSnapshot(userRef, async (querySnapshot) => {
        const data = querySnapshot.data();
        setPaymentReceive(data);
        setPaymentStatus(data?.status?.toLowerCase());
      });
    } catch (err) {
      console.log(
        "Error while attaching listener to the Account firestore",
        err
      );
    }
  };

  const sendPaymentDecode = () => {
    if (isLoggedIn) {
      setPaymentDecodeLoading(true);
      setSendPaymentButtonVisible(true);
      try {
        let amountData;
        decode(values.ln_invoice).sections.find((lnInvoice) => {
          if (lnInvoice.name === "amount") {
            amountData = parseFloat(parseInt(lnInvoice.value) / 1000);
          }
        });
        if (!amountData) setDecodeAmount(false);
        setDecodeData({
          description: decode(values.ln_invoice).description,
          amount: amountData,
          type: lightningPayment,
        });
        setSendRequest(true);
      } catch (e) {
        const withdrawResponse = {
          type: error,
          data: {
            errorMsg: invalidWithdraw,
          },
        };
        setSendResponse(withdrawResponse);
      }
      setPaymentDecodeLoading(false);
    } else {
      sessionStorage.setItem("lnInvoice", values.ln_invoice);
      setPaymentDecodeLoading(false);
      setSendPaymentButtonVisible(true);
      setSignInBox(true);
    }
  };

  const createWithdraw = () => {
    setIsPaymentSendLoading(true);
    setSendResponse({});
    let data = {
      withdraw_request: values.ln_invoice,
      withdraw_method: "lightning",
    };
    if (!decodeData?.amount) data.amount = values.amount;
    let withdrawResponse = {};
    callAPIInterfaceLnDev("POST", "/withdraws", data)
      .then((response) => {
        if (response && response.status) {
          getFirebasePayment(response?.id);
        }
      })
      .catch((_e) => {
        withdrawResponse = {
          type: error,
          data: {
            errorMsg: _e?.response?.data?.errors[0]?.message,
          },
        };
        setSendResponse(withdrawResponse);
        setSendRequest(false);
        setIsPaymentSendLoading(false);
      });
  };

  const detailsTableData = [
    {
      header: (
        <Text variant="h4" size={14} className="default-text" font="regular">
          {amount} :
        </Text>
      ),
      cell: (
        <Text variant="h4" size={14} font="regular">
          {sendResponse?.data?.target_amount_paid}{" "}
          {sendResponse?.data?.currency}
        </Text>
      ),
    },
    {
      header: (
        <Text variant="h4" size={14} className="default-text" font="regular">
          {transactionFees} :
        </Text>
      ),
      cell: (
        <Text variant="h4" size={14} font="regular">
          {sendResponse?.data?.est_fees} {sendResponse?.data?.currency}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Box
        className={(sendRequest && "send-box") || (signInBox && "signIn-box")}
        sx={{
          height: sendRequest && !decodeData?.description && "205px !important",
        }}
      >
        <Box
          className={`flex-display input-box ${
            focusWalletAddress && "focused"
          }`}
        >
          <Box sx={{ width: "30px !important" }}>
            <img
              alt="lightning-logo"
              src={lightningLogo}
              className="lightning-logo"
            />
          </Box>
          <Input
            fullWidth
            showLabel={false}
            placeholder={lnInvoicePlaceholder}
            type="text"
            value={values.ln_invoice}
            onChange={(e) => {
              setSendResponse({});
              setFieldValue("ln_invoice", e.target.value);
              setSendPaymentButtonVisible(false);
              setSendRequest(false);
            }}
            onFocus={() => setFocusWalletAddress(true)}
            onBlur={() => setFocusWalletAddress(false)}
            className="lndev-input wallet-address-box"
          />
          {/* <Box sx={{ width: "50px !important" }}>
            <img
              alt="addQrCode-logo"
              src={addQrCodeLogo}
              style={{ padding: "10px 0 0 15px" }}
            />
          </Box> */}
        </Box>
        {signInBox && (
          <SignInBox
            setSendPaymentButtonVisible={setSendPaymentButtonVisible}
            setSignInBox={setSignInBox}
            type={send}
          />
        )}
        {sendRequest && (
          <Box
            className="send-payment-request"
            sx={{ height: !decodeData?.description && "120px !important" }}
          >
            <Box
              height={15}
              width={15}
              onClick={() => {
                setSendPaymentButtonVisible(false);
                setSendRequest(false);
              }}
              component="span"
              className="pointer-cursor closeIcon"
            >
              <CloseIcon sx={{ height: 15, width: 15 }} htmlColor="#848b9e" />
            </Box>
            <Box className="flex-display send-request-data">
              <Box>
                <TruncatedTextTooltip
                  className="send-payment-text"
                  textValue={decodeData?.description}
                  cellWidth="230px"
                />
                {decodeData?.amount ? (
                  <>
                    <Box>
                      <Text
                        font="semibold"
                        className="send-amount"
                        size={24}
                        variant="h4"
                      >
                        {decodeData?.amount} {sats}
                      </Text>
                    </Box>
                    <Text
                      className="default-text send-payment-type"
                      font="semibold"
                      size={14}
                      variant="h4"
                      sx={{ fontFamily: "Inter !important" }}
                    >
                      {decodeData?.type}
                    </Text>
                  </>
                ) : (
                  <>
                    <Box>
                      <Text
                        className="default-text send-payment-type"
                        font="semibold"
                        size={14}
                        variant="h4"
                        sx={{ fontFamily: "Inter !important" }}
                      >
                        {amountPlaceholder}
                      </Text>
                    </Box>
                    <Box sx={{ paddingTop: "5px" }}>
                      <Input
                        fullWidth
                        showLabel={false}
                        type="text"
                        value={values.amount || ""}
                        onChange={(e) => {
                          const { value } = e.target;
                          !/[^\d]/.test(value) &&
                            setFieldValue(
                              "amount",
                              value.length > 0 ? parseInt(value) : value
                            );
                        }}
                        className="send-amount-input"
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box>
                <Box className="send-payment-btn" sx={{ paddingRight: "20px" }}>
                  <Button
                    label={send}
                    icon="sendIcon"
                    loading={isPaymentSendLoading}
                    disabled={
                      (!decodeData?.amount && values.amount === 0) ||
                      !(isValid && dirty)
                    }
                    onClick={createWithdraw}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {!sendButtonVisible && (
          <Box className="lndev-payment-btn">
            <Button
              style={{ width: "190px" }}
              label={sendPayment}
              loading={isPaymentDecodeLoading}
              disabled={!(isValid && dirty)}
              onClick={() => sendPaymentDecode()}
            />
          </Box>
        )}
      </Box>

      {Object.keys(sendResponse).length > 0 && sendResponse.type === error && (
        <ToastMessage
          tableClassName="already-paid-toast"
          text={sendResponse?.data?.errorMsg}
          icon={
            <CancelIcon className="status-icon" sx={{ color: "#e72f2f" }} />
          }
        />
      )}
      <Box display="flex" flexDirection="column" alignItems="center">
        {Object.keys(sendResponse).length > 0 && sendResponse.type === unpaid && (
          <Box className="payment-processing-box">
            <Box className="payment-processing-content">
              <HourglassBottomIcon
                sx={{
                  color: "#848B9E",
                  width: "24px",
                  height: "24px",
                }}
              />
              <Text
                font="regular"
                className="payment-processing-text grey-text"
                style={{ paddingTop: "13px" }}
                size={14}
                variant="subtitle1"
              >
                {paymentProcessedMsg[0]}
              </Text>
              <Text
                font="regular"
                className="payment-processing-text grey-text"
                size={14}
                variant="subtitle1"
              >
                {paymentProcessedMsg[1]}
              </Text>
            </Box>
          </Box>
        )}
      </Box>

      {Object.keys(sendResponse).length > 0 && sendResponse.type === success && (
        <Box
          className={`toast-send-messages ${
            !showMorePayment ? "show-less-payment" : "show-more-payment"
          }`}
        >
          <Box className="flex-display">
            <Box className="flex-display" sx={{ paddingLeft: "21px" }}>
              <CheckCircleIcon
                className="success-icon"
                sx={{ color: "#2ecc71" }}
              />
              <Text
                font="semibold"
                size={16}
                variant="h4"
                className="send-payment"
              >
                {paymentSent}
              </Text>
            </Box>
            {!showMorePayment ? (
              <Text
                font="regular"
                withIcon="end"
                className="viewMore-ln-send-message"
                size={10}
                variant="subtitle1"
                onClick={() => setShowMorePayment(true)}
              >
                {showMore}
                <KeyboardArrowDownIcon className="view-more-text-icon" />
              </Text>
            ) : (
              <Text
                font="regular"
                withIcon="end"
                className="viewMore-ln-send-message"
                size={10}
                variant="subtitle1"
                onClick={() => setShowMorePayment(false)}
              >
                {showLess}
                <KeyboardArrowUpIcon className="view-more-text-icon" />
              </Text>
            )}
          </Box>
          <Box className="payment-description">
            {sendResponse?.description && (
              <Box>“ {sendResponse?.description} “</Box>
            )}
          </Box>
          {showMorePayment && (
            <VerticalTable
              rowData={detailsTableData}
              className="users-vertical-data"
            />
          )}
          <Box className="amount-sent">
            <Text
              size={20}
              font="semibold"
              variant="h4"
              className="send-total-amount"
            >
              {`${totalAmount}: ${
                sendResponse?.data?.target_amount_paid +
                sendResponse?.data?.est_fees
              } ${sendResponse?.data?.currency ?? sats}`}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};
export default Send;
