import * as Types from "./types";

const initialState = {
  isLoggedIn: !!localStorage.getItem("site_auth"),
  user: null,
  accessToken: "",
  idToken: "",
  session: "",
  liveMode: false, // LN.dev is working in only test mode.
  currentAccount: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case Types.SET_INITIAL_DATA:
      const { access_token, id_token, session, current_acc } = action.payload;
      let initialData = {
        ...state,
        accessToken: access_token,
        idToken: id_token,
        session,
      };
      if (current_acc) {
        initialData.currentAccount = current_acc;
      }
      return initialData;
    case Types.SET_USER:
      const { first_name, last_name } = action.payload;
      return {
        ...state,
        user: { name: first_name + " " + last_name, ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default auth;
