import { Box, Skeleton } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@speed/common/src/components/Button/Button";
import HeaderUserProfile from "@speed/common/src/components/HeaderUserProfile";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { signIn, signUp } from "@speed/common/src/components/messages";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logOut } from "../redux/auth/actions";
import { dashboard } from "./components/messages";
import AccountSelection from "./components/Common/AccountSelection";

const Header = ({ changeAccountFlag }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isMobile = useSelector((state) => state.common.isMobile);

  const [isLoading, setLoading] = useState(false);

  const queryParams = new URLSearchParams({
    redirect: window.location.href,
    require_firebase_token: true,
  });

  const commonFlexStyles = {
    flexGrow: 1,
    flexBasis: "0px",
  };

  return (
    <Box className={`header user-in mui-fixed ${!isLoggedIn && 'header-remove-border'}`}>
      <CssBaseline />
      <Container maxWidth="xl">
        <AppBar position="static" className={!isLoggedIn && 'appbar-header-isloggedIn'}>
          <Toolbar>{isLoggedIn ?
            <><Box style={commonFlexStyles}>
              {user ? (
                <AccountSelection changeAccountFlag={changeAccountFlag} />
              ) : (
                <Skeleton animation="wave" width={150} />
              )}
            </Box>
              <Box
                style={{
                  ...commonFlexStyles,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <CustomLink
                  size="small"
                  bold={true}
                  sx={{ color: "#0a193e", paddingRight: "20px" }}
                  href={process.env.REACT_APP_URL + "/dashboard"}
                  target={"_blank"}
                >
                  {dashboard}
                </CustomLink>

                {user ? (
                  <HeaderUserProfile
                    profile={true}
                    profileHref={process.env.REACT_APP_URL + "/settings/profile"}
                    user={user}
                    isLoading={isLoading}
                    logOut={() => {
                      setLoading(isLoggedIn);
                      dispatch(logOut())
                        .then(() => setLoading(false))
                        .catch(() => setLoading(false));
                    }}
                  />
                ) : (
                  <Skeleton
                    variant="circular"
                    width={30}
                    height={30}
                    animation="wave"
                  />
                )}
              </Box>
            </> :
            <>
              <Box
                style={{
                  ...commonFlexStyles,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box className="flex-display" sx={{ justifyContent: "space-between", paddingRight: `${!isMobile && '20px'}` }}>
                  <CustomLink
                    size="small"
                    bold={true}
                    sx={{ color: "#0a193e", paddingRight: "30px" }}
                    href={
                      process.env.REACT_APP_URL + `/login?${queryParams.toString()}`
                    }
                  >
                    {signIn}
                  </CustomLink>
                  <CustomLink
                    size="small"
                    bold={true}
                    href={
                      process.env.REACT_APP_URL + `/register?${queryParams.toString()}`
                    }
                  >
                    <Button label={signUp} sx={{ padding: "12px !important" }} />
                  </CustomLink>
                </Box>
              </Box>
            </>}
          </Toolbar>
        </AppBar>
      </Container>
    </Box>);
};

export default Header;
