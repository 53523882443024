import React from "react";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useSelector } from "react-redux";

const PageLoader = () => {
  const { isPageLoader, pageLoaderText } = useSelector((state) => state.common);
  return (
    <BackdropLoader
      open={isPageLoader}
      text={pageLoaderText}
      alt={"loading..."}
      customClass="loading-in-auth"
    />
  );
};

export default PageLoader;
