import { Box } from "@mui/material";
import { lnDevLogo, warningLogo } from "@speed/common/src/components/images";
import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import Header from "../Header";
import Send from "./Send";
import Receive from "./Receive";
import Notification from "./Common/Notification";
import { useLocation, useHistory } from "react-router-dom";
import {
  generateToken,
  getUser,
  setInitialState,
} from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import PageLoader from "./Common/PageLoader";
import { showToast } from "../../redux/common/actions";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import {
  accessDeniedForDevepers,
  accessDeniedForSpecAccount,
  sessionExpireSpeed,
  gotIt,
  changeAccountLabel,
} from "./messages";
import {
  getFirestoreData,
  callAPIInterfaceLnDev,
  expireSession,
} from "./constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import {
  fetchPrecisedInteger,
  fetchRawBalanceData,
} from "@speed/common/src/components/constants";

const LnDev = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [balances, setBalances] = useState(false);
  const [balanceLoader, setBalanceLoader] = useState(false);
  const [accountUnableAccess, setAccountUnableAccess] = useState(false);
  const [changeAccount, setChangeAccount] = useState(false);
  const [changeAccountFlag, setChangeAccountFlag] = useState(false);

  const { isLoggedIn, currentAccount, user } = useSelector(
    (state) => state.auth
  );

  const tab = "1";
  const [selectedTab, setSelectedTab] = useState(tab);

  const loadInitialData = async () => {
    await sessionService
      .loadSession()
      .then((session) => {
        dispatch(setInitialState(session));
      })
      .catch((_e) => {});
  };

  const getBalance = () => {
    setBalanceLoader(true);
    callAPIInterfaceLnDev("GET", "/balances")
      .then((response) => {
        if (response) {
          const rawData = fetchRawBalanceData(response);
          const data = rawData?.find(
            (data) => data?.target_currency === "SATS"
          );
          setTotalAmount(data?.amount);
        }
        setBalanceLoader(false);
        setBalances(false);
      })
      .catch(() => {
        setBalanceLoader(false);
      });
  };

  useEffect(() => {
    if (isLoggedIn && balances) getBalance();
  }, [balances]);

  const content = (
    <Box textAlign="center" padding="9px 0">
      <img
        src={warningLogo}
        alt="warning"
        height="52px"
        width="52px"
        style={{ marginTop: "12px" }}
      />
      <Text marginBottom="21px" className="margin-top15 developer-account-desc">
        {changeAccount
          ? accessDeniedForSpecAccount(currentAccount?.account?.name)
          : accessDeniedForDevepers}
      </Text>
      <Button
        sx={{ marginTop: "7px" }}
        label={changeAccount ? changeAccountLabel : gotIt}
        fullWidth
        onClick={() => {
          changeAccount ? setChangeAccountFlag(true) : expireSession();
          setAccountUnableAccess(false);
        }}
      />
    </Box>
  );
  useEffect(() => {
    if (user?.accounts_details.length >= 1) {
      if (currentAccount?.role?.role_name === "Developer") {
        setAccountUnableAccess(true);
        const accountLists = user.accounts_details.map(
          (accountData) => accountData.role
        );
        const accountRoleLists = accountLists.filter(
          (accountData) => accountData.role_name === "Developer"
        );
        accountRoleLists?.length !== user?.accounts_details.length &&
          setChangeAccount(true);
      }
    }
  }, [user, currentAccount?.account?.id]);

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const session = queryParams.get("session");
      const refreshToken = queryParams.get("refresh_token");
      const fsToken = queryParams.get("fs_token");

      if (session) {
        const obj = { isExpired: false };
        const bytesSession = AES.decrypt(
          session,
          process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
        );
        const bytesRefreshToken = AES.decrypt(
          refreshToken,
          process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
        );
        const decipherSession = bytesSession.toString(UTF8);
        const decipherRefreshToken = bytesRefreshToken.toString(UTF8);
        obj.session = decipherSession;
        obj.refreshToken = decipherRefreshToken;

        let decipherFsToken = "";
        if (fsToken) {
          const tokenBytes = AES.decrypt(
            fsToken,
            process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
          );
          decipherFsToken = tokenBytes.toString(UTF8);
          obj.firebaseToken = decipherFsToken;
        }
        history.replace("/");
        generateToken(obj).catch((err) => {
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: sessionExpireSpeed,
              toastVariant: "error",
            })
          );
        });
      }
    } else {
      loadInitialData();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUser());
    }
  }, [isLoggedIn]);

  const setFirestore = async () => {
    await getFirestoreData(user);
  };

  useEffect(() => {
    if (user) {
      setFirestore();
      getBalance();
    }
  }, [user]);

  const tabData = [
    {
      label: "Send",
      value: "1",
      tabContent: <Send setBalances={setBalances} />,
      tabName: "Send",
    },
    {
      label: "Receive",
      value: "2",
      tabContent: <Receive setBalances={setBalances} />,
      tabName: "Receive",
    },
  ];

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Header changeAccountFlag={changeAccountFlag} />
      <Box container className="lndev-content">
        <Box className="lndev-content-data">
          <Box className="d-flex center-content">
            <img alt="lndev-logo" src={lnDevLogo} />
          </Box>
          <Box className="tab-data-lndev">
            <CustomTab
              onChange={handleChange}
              tabData={tabData}
              selectedTab={selectedTab}
              noScroll={true}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        body={content}
        open={accountUnableAccess}
        closeIcon={true}
        className="developer-account-popup"
      />
      <Footer
        amount={fetchPrecisedInteger(totalAmount, 8)}
        balanceLoader={balanceLoader}
      />
      <PageLoader />
      <Notification />
    </>
  );
};

export default LnDev;
