import React from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";

const ToastMessage = ({ tableClassName, icon, text, amountData }) => {
  return (
    <Box className={`toast-message flex-display ${tableClassName}`}>
      <Box className="flex-display toast-description">
        {icon}
        <Text
          font="semibold"
          className="toast-description-data"
          size={16}
          variant="h4"
        >
          {text}
        </Text>
      </Box>
      <Box>
        <Text
          font="semibold"
          className="toast-amount-data"
          size={20}
          variant="h4"
        >
          {amountData}
        </Text>
      </Box>
    </Box>
  );
};

export default ToastMessage;
