import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import auth from "./auth/reducer";
import common from "./common/reducer";

const lnDevReducer = combineReducers({
  auth,
  common,
  session: sessionReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return lnDevReducer(undefined, action);
  }

  return lnDevReducer(state, action);
};

export default rootReducer;
