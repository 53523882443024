import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { speedPreloader } from "@speed/common/src/components/images";
import { Text } from "@speed/common/src/components/Text/Text";
import classNames from "classnames";

const BackdropLoader = ({ open, text, alt, customClass }) => {
  const classes = classNames("backdrop-loader", customClass);

  return (
    <Backdrop className={classes} open={open}>
      <Card>
        <CardMedia component="img" image={speedPreloader} alt={alt} />
        <CardContent>
          <Text
            variant="h4"
            size={14}
            font="regular"
            className="grey-text"
            align="center"
          >
            {text}
          </Text>
        </CardContent>
      </Card>
    </Backdrop>
  );
};

export default BackdropLoader;
