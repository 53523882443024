import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames";
import React from "react";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";

const VerticalTable = ({ label, rowData, className, rightSideRowData }) => {
  return (
    <Box className={classNames("vertical-table-wrapper", className)}>
      {label && (
        <>
          <Text size={20} className="default-text">
            {label}
          </Text>
          <CustomDivider sx={{ marginTop: "12px" }} />
        </>
      )}
      <Table sx={{ display: "flex", justifyContent: "space-between" }}>
        <TableBody sx={rightSideRowData ? { width: "50%" } : {}}>
          {rowData?.length > 0 &&
            rowData.map((row) => {
              return (
                <TableRow key={row.header}>
                  <TableCell variant="head" sx={row.headerStyle || {}}>
                    {row.header}
                  </TableCell>
                  <TableCell>{row.cell}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        {rightSideRowData && (
          <TableBody sx={{ width: "50%" }}>
            {rightSideRowData.map((row) => {
              return (
                <TableRow key={row.header}>
                  <TableCell variant="head" sx={row.headerStyle || {}}>
                    {row.header}
                  </TableCell>
                  <TableCell>{row.cell}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </Box>
  );
};

export default VerticalTable;
