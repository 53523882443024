import { Router } from "react-router-dom";
import history from "@speed/common/src/components/history";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Main from "./container/Main";
import "./assets/styles/main.scss";
import { StyledEngineProvider } from "@mui/material/styles";

const App = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <Router history={history}>
          <Main />
        </Router>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
