import PropTypes from "prop-types";
import "./tabs.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const CustomTab = ({
  selectedTab,
  onChange,
  tabData,
  setTabRoute,
  sx,
  noScroll,
  height,
  className,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        minHeight: `${noScroll ? "" : "100vh"}`,
        ...sx,
      }}
      className={className}
    >
      {tabData && tabData.length > 0 && (
        <TabContext value={selectedTab}>
          <TabList onChange={onChange}>
            {tabData.map((tab, index) => {
              return (
                <Tab
                  onClick={() => setTabRoute && setTabRoute(tab.route)}
                  label={tab.label}
                  value={tab.value}
                  key={index}
                  disabled={tab.disabled ? tab.disabled : false}
                />
              );
            })}
          </TabList>
          {tabData.map((tab, index) => {
            return (
              <TabPanel
                value={tab.value}
                key={index}
                sx={{
                  minHeight: `${
                    noScroll ? "" : `calc(100vh - ${height || "45px"})`
                  }`,
                }}
              >
                {tab.tabContent}
              </TabPanel>
            );
          })}
        </TabContext>
      )}
    </Box>
  );
};

export const propTypes = {
  onChange: PropTypes.func,
  setTabRoute: PropTypes.func,
  className: PropTypes.string,
  tabData: PropTypes.array.isRequired,
};

CustomTab.propTypes = propTypes;
