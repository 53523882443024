import * as Types from "./types";

export const showToast = (data) => (dispatch) => {
  dispatch({
    type: Types.SHOW_TOAST_MESSAGE,
    payload: data,
  });
};

export const hideToast = (data) => (dispatch) => {
  dispatch({
    type: Types.HIDE_TOAST_MESSAGE,
    payload: data,
  });
};

export const setPageLoader = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_PAGE_LOADER,
    payload: data,
  });
};
