import axios from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/react";
import { sessionService } from "redux-react-session";
import { speedVersion } from "@speed/common/src/components/constants";
import { store } from "../../redux/store";
import { RESET_STORE } from "../../redux/auth/types";
import {
  generateToken,
  getUser,
  resetPageLoader,
  setIsLoggedIn,
} from "../../redux/auth/actions";
import history from "@speed/common/src/components/history";
import { setPageLoader, showToast } from "../../redux/common/actions";
import { sessionExpireSpeed } from "./messages";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { app } from "@speed/common/src/util/firebase";

export const number = /^[0-9]{1,16}$/;
export const minSat = 0;
export const minSendSat = 1;
export const failureReasonNoRoute = "FAILURE_REASON_NO_ROUTE";

const getHeaders = async (method, path, data) => {
  const host = process.env.REACT_APP_API_ENDPOINT;
  const jwtOptions = {
    method,
    url: `${host}${path}`,
  };

  if (
    data ||
    method === "POST" ||
    method === "GET" ||
    method === "DELETE" ||
    method === "PUT"
  ) {
    jwtOptions.data = data;
    jwtOptions.headers = jwtOptions.headers || {};
    jwtOptions.headers["Content-Type"] = "application/json";
    jwtOptions.headers["Accept"] = "application/json";
  }

  const isEndPointIncluded = (endPointsList, endPoint) =>
    endPointsList.includes(endPoint);

  const openAPIEndPoints = ["/generate-token"];
  const isOpenAPI = isEndPointIncluded(openAPIEndPoints, path);

  if (!isOpenAPI) {
    await sessionService
      .loadSession()
      .then(async (session) => {
        if (session.access_token) {
          jwtOptions.headers["Access"] = session.access_token;
          jwtOptions.headers["speed-version"] = speedVersion;
        }
        if (session.id_token) {
          jwtOptions.headers["Authorization"] = session.id_token;
        }

        jwtOptions.headers["speed-livemode"] = false; // LN.dev is working in test mode only;

        if (session.speed_acc_id) {
          jwtOptions.headers["speed-account"] = session.speed_acc_id;
        }
      })
      .catch((_err) => {});
  }

  return jwtOptions;
};

export const serverErrorStatusCodes = [500, 502, 503, 504];
export const errorStatusCodes = [400, 401, 402, 403, 404, 408, 415, 428, 429];

const redirectAfterError = (err, reject) => {
  Sentry.captureException(err);
  store.getState().session.authenticated ? expireSession() : history.push("/");
  reject(503);
};

const handleTokenExpiration = async (path, jwtOptions, resolve) => {
  const currentSession = await sessionService.loadSession();
  const obj = {
    session: currentSession.session,
    isExpired: true,
  };
  const tokens = await generateToken(obj);

  jwtOptions.headers["Access"] = tokens.access_token;
  jwtOptions.headers["Authorization"] = tokens.id_token;

  axiosRetry(
    axios({
      ...jwtOptions,
      transformResponse: (r) =>
        r && (pathsToFetchRawData.includes(path) ? r : JSON.parse(r)),
    })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(() => {
        // Any error occurred while retring API call
        expireSession();
        path !== "/logout" &&
          store.dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: sessionExpireSpeed,
              toastVariant: "error",
            })
          );
      }),
    { retries: 1 }
  );
};

const handleTokenExpirationError = (err, path) => {
  const error = err && err.response && err.response.data.errors[0];

  if (error) {
    expireSession();
    path !== "/logout" &&
      store.dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: sessionExpireSpeed,
          toastVariant: "error",
        })
      );
  }
};

const handleSantryErrorCodes = async (err, reject) => {
  const errors = err.response?.data?.errors;
  const errorStatus = err.response?.status;

  Sentry.captureException(err);
  store.getState().session.authenticated
    ? await expireSession()
    : history.push("/");
  errors &&
    errors[0].message &&
    store.dispatch(
      showToast({
        isToastOpen: true,
        toastMessage: errors[0].message,
        toastVariant: "error",
      })
    );
  reject(errorStatus);
};

const pathsToFetchRawData = ["/balances"];

// axios API call
export const callAPIInterfaceLnDev = (method, path, data = "") => {
  return new Promise(async (resolve, reject) => {
    let jwtOptions = await getHeaders(method, path, data);
    let apiCall = axios({
      ...jwtOptions,
      transformResponse: (r) =>
        r && (pathsToFetchRawData.includes(path) ? r : JSON.parse(r)),
    });
    apiCall
      .then((res) => {
        resolve(res?.data);
      })
      .catch(async (err) => {
        if (err.response) {
          if (pathsToFetchRawData.includes(path)) {
            err.response.data = JSON.parse(err.response.data || "{}");
          }
          const errors = err.response?.data?.errors;
          const errorStatus = err.response?.status;
          const errorType =
            errors && errors[0].type && errors[0].type.toLowerCase();

          if (serverErrorStatusCodes.includes(errorStatus)) {
            handleSantryErrorCodes(err, reject);
          } else if (errorStatusCodes.includes(errorStatus)) {
            if (errorType === "token_expired") {
              try {
                await handleTokenExpiration(path, jwtOptions, resolve);
              } catch (err) {
                // Any error occurred while generate-token API
                handleTokenExpirationError(err, path);
              }
            } else if (errorType === "unauthorized") {
              expireSession();
            } else {
              reject(err);
            }
          } else {
            redirectAfterError(err, reject);
          }
        } else if (err.request) {
          redirectAfterError(err, reject);
        }
      });
  });
};

export const getTimestamp = () => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + 10);
  return date.getTime();
};

export const expireSession = async () => {
  const authObj = getAuth(app);
  await signOut(authObj);

  sessionService.deleteSession();
  sessionService.deleteUser();
  localStorage.clear();

  store.dispatch({
    type: RESET_STORE,
  });
  store.dispatch(setIsLoggedIn(false));
};

export const getFirestoreData = async (user) => {
  const authObj = getAuth(app);
  const db = getFirestore(app);
  const userRef = doc(db, "user", user.id);
  try {
    onSnapshot(
      userRef,
      async (querySnapshot) => {
        const data = querySnapshot.data();

        if (data) {
          const session = await sessionService.loadSession();
          const timestamp = session && session.timestamp;

          if (
            data.global_sign_out_timestamp &&
            timestamp < data.global_sign_out_timestamp
          ) {
            expireSession();
          }

          if (
            data.token_updated_timestamp &&
            timestamp < data.token_updated_timestamp
          ) {
            store.dispatch(
              setPageLoader({
                isPageLoader: true,
                pageLoaderText: "Loading...",
              })
            );

            const obj = {
              session: session.session,
              isExpired: true,
            };
            generateToken(obj)
              .then(async () => {
                await store.dispatch(getUser());
                store.dispatch(resetPageLoader());
              })
              .catch(() => {
                store.dispatch(resetPageLoader());
              });
          }
        }
      },
      async (e) => {
        await authObj.currentUser?.getIdToken();
        store.dispatch(resetPageLoader());
      }
    );
  } catch (e) {
    store.dispatch(resetPageLoader());
  }
};
