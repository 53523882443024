export const lnInvoicePlaceholder = "Enter LN Invoice";
export const satsAmountPlaceholder = "Enter amount in SATs";
export const amountPlaceholder = "Enter Amount";
export const sendPayment = "Send Payment";
export const receivePayment = "Receive Payment";
export const lightningPaymentRequest = "Lightning Payment Request";
export const paymentSent = "Payment Sent";
export const sats = "SATS";
export const totalAmount = "Total Amount";
export const transactionId = "Transaction id";
export const transactionFees = "Transaction Fees";
export const lightningPayment = "Lightning Payment";
export const showMore = "Show more";
export const showLess = "Show less";
export const invalidWithdraw =
  "The LN Invoice is invalid. Please enter the correct LN Invoice.";
export const insufficientBalance = "Insufficient balance in account.";
export const alreadyPaidInvoice = "Invoice already paid";
export const unableSendPayment =
  "Unable to send payment! Please try with another LN Invoice.";
export const userErrorMsg = "Error while retrieving user details.";
export const dashboard = "Dashboard";
export const searchAccount = "Search Account";
export const send = "Send";
export const receive = "Receive";
export const sessionExpireSpeed =
  "Your session has expired on speed. Please login again in speed to continue with LN.dev";
export const changeAccountLabel = "Change Account";
export const gotIt = "Got it";
export const accessDeniedForDevepers = `You've logged in as "developer" hence won't be able to access this application.`;
export const accessDeniedForSpecAccount = (accoutName) => (
  <>
    You have developer access to this <b>{accoutName}</b> account. Please select
    another account to continue.
  </>
);
export const speedAccountloginMsg =
  "Please sign up / sign in using Speed account";
export const balanceText = (totalAmount, amountType) =>
  `Available balance is ${totalAmount} ${amountType}`;
export const paymentProcessedMsg = [
  "Your payment is being processed; however, it may take some time to complete.",
  "You can check your wallet or the transactions part of the Speed Web application for its updated details.",
];
export const alreadyPaid = "already paid";
export const failed = "failed";
export const paid = "paid";
export const unpaid = "unpaid";
export const amount = "amount";
export const success = "success";
export const error = "error";
