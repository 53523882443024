import { Box } from "@mui/material";
import { mainLogo, slackLogo, twitterLogo } from "@speed/common/src/components/images";
import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import Balance from "./components/Common/Balance";
import { useSelector } from "react-redux";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { poweredBy } from "@speed/common/src/messages";

const Footer = ({ amount, balanceLoader }) => {
  const isMobile = useSelector((state) => state.common.isMobile);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <Box className="footer">
      <Box>
        {isLoggedIn && <Balance totalAmount={amount} balanceLoader={balanceLoader} balanceClassName="mobile-balance" />}
      </Box>
      <Box className="flex-display">
        <Box className="layout-box">
          {/* <Box>
            <img src={slackLogo} height={30} width={30} alt="slack" />
          </Box>
          <Box sx={{ paddingLeft: '20px' }}> */}
          <Box>
            <CustomLink
              size="small"
              bold={true}
              href="https://twitter.com/ln_lndev"
              target="_blank"
            >
              <img src={twitterLogo} height={30} width={30} alt="twitter" />
            </CustomLink>
          </Box>
        </Box>
        <Box className="secured-by-footer" sx={{ paddingRight: `${!isMobile && '20px'}` }}>
          <Text
            size={12}
            className="grey-text"
            font="semibold"
            variant="subtitle1"
            sx={{ paddingRight: '15px' }}
          >
            {poweredBy}
          </Text>
          <img src={mainLogo} height={30} alt="speed" />
        </Box>
      </Box>

    </Box>
  );
};

export default Footer;